import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Testimonials from "../components/testimonials"
import CTA from "../components/cta"
import SubServicesAlt from "../components/subServicesAlt"

function AboutPage() {
  const data = useStaticQuery(graphql`
    query aboutImages {
      sidBrutonPlumber: file(relativePath: { eq: "sid-bruton-plumber.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="About Us"
        description={`Klein Plumbing Services has been serving the North Houston area for over 14 years. Our family-owned plumbing company is experienced and affordable.`}
      />
      <section>
        <div className="py-16 px-2 md:px-0">
          <h1 className="text-4xl md:text-center font-semibold text-blue-400 pb-16">
            About Klein Plumbing Services
          </h1>
          <div className="md:flex">
            <div className="md:w-full md:mr-8 mb-8 lg:mb-0">
              <div className="flex md:justify-center items-center">
                <Img
                  className="justify-center rounded-full w-64 h-64 shadow-lg"
                  fluid={data.sidBrutonPlumber.childImageSharp.fluid}
                  alt="Sid Bruton Master Plumber"
                />
              </div>
            </div>
            <div className="md:flex items-center">
              <div className="md:w-full md:text-left">
                <div className="md:flex ">
                  <h2 className="font-bold leading-8 tracking-tight text-2xl mb-4">
                    Your Local Master Plumber
                  </h2>
                </div>
                <div className="">
                  <p className="text-gray-600 text-lg mb-4">
                    Klein Plumbing is a family owned and operated business with
                    over 30 years of experiencing serving the North Houston
                    community in residential plumbing.
                  </p>
                  <p className="text-gray-600 text-lg mb-4">
                    Owner and Master Plumber, Sid Bruton, started his own
                    company in 2006 after years of honing his craft in his
                    family's plumbing business. He prides himself on his
                    attention to detail, thoroughness, and client-oriented
                    service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SubServicesAlt
        mainTitle={`Our Core Values`}
        titleOne={`Integrity`}
        descriptionOne={` At the core of Klein Plumbing Services is integrity - your
        plumbing repair is treated exactly how we'd want it done
        ourselves. Our clients are treated like family.`}
        titleTwo={`Transparency`}
        descriptionTwo={`Our pricing is transparent and straightforward with no hidden fees. We suggest the best solution for your particular need at an affordable price.`}
        titleThree={`Competency`}
        descriptionThree={`You deserve to have your plumbing issue fixed correctly the first time. With over 30 years of experience, we're confident that we have the skills to repair your problem.`}
      />
      {/* <Testimonials /> */}
      <CTA />
    </Layout>
  )
}

export default AboutPage
